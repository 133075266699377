<template>
  <v-app class="register-wrap">
    <v-row class="d-flex align-center">
      <v-col
        sm="12"
        class="login-wrap px-8 text-center d-flex flex-column justify-center"
      >
        <h1 class="mt-2 mb-6 logo-wrap d-flex align-center justify-center">
          <img :src="require('@/assets/logo.svg')" />
          <span class="pt-1 gradient-text">Kerala Library Registration</span>
        </h1>
        <v-card
          tile
          class="mx-auto pb-6 px-9 mb-10 login-box"
          width="600"
          outlined
          elevation="5"
          :disabled="loading"
          :loading="loading"
        >
          <v-form
            ref="form"
            class="pt-6"
            v-model="valid"
            lazy-validation
            @submit.prevent="submit"
          >
            <input
              type="email"
              name="user_name"
              id="user_name"
              value=""
              class="opacity-hide"
            />
            <input
              type="password"
              name="password"
              id="password"
              value=""
              class="opacity-hide"
            />
            <div :class="['inputs-wrap', step1Done && 'step-1-done']">
              <div class="step-1">
                <v-text-field
                  v-model="formData.name"
                  :rules="nameRules"
                  label="Library Name"
                  required
                  tabindex="1"
                  class="my-1"
                ></v-text-field>
                <v-text-field
                  v-model="formData.email"
                  :rules="emailRules"
                  label="Email"
                  required
                  tabindex="1"
                  class="my-1"
                ></v-text-field>
                <v-text-field
                  v-model="formData.regNo"
                  :rules="[required, length(1, 25)]"
                  label="Registration Number"
                  required
                  tabindex="1"
                  class="my-1"
                ></v-text-field>
                <v-text-field
                  v-model="formData.username"
                  :rules="usernameRules"
                  label="Username"
                  required
                  tabindex="1"
                  class="my-1"
                ></v-text-field>
                <v-textarea
                  v-model="formData.address"
                  rows="1"
                  :rules="[required, length(1, 300)]"
                  label="Address"
                  required
                  tabindex="1"
                  class="my-1"
                ></v-textarea>
                <v-select
                  v-model="formData.district"
                  :rules="[required]"
                  label="District"
                  :items="districts"
                  tabindex="1"
                  class="my-1"
                ></v-select>
                <v-text-field
                  v-model="formData.pin"
                  v-mask="'######'"
                  :rules="[
                    required,
                    (v) => (v.length === 6 ? true : 'Enter a valid Pin Code')
                  ]"
                  label="Pin Code"
                  required
                  tabindex="1"
                  class="my-1"
                ></v-text-field>
                <v-text-field
                  v-model="formData.phone"
                  v-mask="'############'"
                  :rules="[
                    required,
                    (v) =>
                      v.length >= 10
                        ? true
                        : 'Enter a valid Indian phone number without country code'
                  ]"
                  label="Phone"
                  required
                  tabindex="1"
                  class="my-1"
                ></v-text-field>
              </div>
              <input value="" class="opacity-hide" />
              <div class="step-2">
                <h3 class="password-title">Set your password</h3>
                <v-text-field
                  type="password"
                  v-model="formData.password"
                  :rules="passwordRules"
                  label="Password"
                  required
                  tabindex="1"
                  class="my-1"
                ></v-text-field>
                <v-text-field
                  type="password"
                  v-model="formData.confirmPassword"
                  :rules="confirmPasswordRules"
                  label="Confirm Password"
                  required
                  tabindex="1"
                  class="my-1"
                ></v-text-field>
              </div>
            </div>
            <div class="d-flex justify-end align-center pt-1">
              <template v-if="!step1Done">
                <span class="back-to-login">
                  Already have an account?
                  <a @click.prevent="gotoLogin">Login here</a></span
                >
                <v-btn
                  tile
                  small
                  color="primary"
                  class="ml-4"
                  tabindex="4"
                  @click.prevent="completeStep1"
                  type="submit"
                >
                  Next
                </v-btn>
              </template>
              <template v-else>
                <v-btn
                  tile
                  small
                  color="primary"
                  class="ml-4"
                  tabindex="4"
                  @click.prevent="step1Done = false"
                >
                  Previous
                </v-btn>
                <v-btn
                  small
                  type="submit"
                  tile
                  color="success"
                  class="ml-4"
                  tabindex="4"
                >
                  Sign Up
                </v-btn>
              </template>
            </div>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import { useRegister } from "@/modules";

export default {
  setup: useRegister
};
</script>

<style lang="scss" scoped>
@function getGradient($angle, $color1: #0899ec, $color2: #15b998) {
  @return linear-gradient($angle, $color1, $color2);
}
.logo-wrap {
  img {
    width: 50px;
  }
}
.register-wrap {
  background: none;
}
.login-wrap {
  height: 100%;
  .register-text {
    font-size: 14px;
    text-align: left;
    margin-top: 20px;
  }
  h1 {
    line-height: 0.95;
    text-align: left;
    span {
      font-size: 28px;
      padding-left: 20px;
    }
  }
}
.login-content {
  height: 100%;
  position: relative;
  &::before {
    content: "";
    background: getGradient(12deg);
    position: absolute;
    left: 0;
    right: -50%;
    top: -50%;
    bottom: -50%;
    z-index: 0;
    transform: rotate(4deg);
  }
  & > div {
    height: 100%;
    position: relative;
    z-index: 1;
    color: #fff;
    .feature-note {
      font-size: 50px;
    }
    .image-wrap {
      img {
        width: 250px;
        margin-top: 10px;
      }
    }
  }
}
::v-deep .v-text-field__details {
  margin-top: -5px;
  margin-bottom: 3px;
  .v-messages__message {
    font-size: 10px;
  }
}
.back-to-login {
  font-size: 13px;
  flex: 1;
  text-align: left;
}

.inputs-wrap {
  &.step-1-done {
    .step-1 {
      opacity: 0;
      height: 0;
      overflow: hidden;
    }
  }
  &:not(.step-1-done) {
    .step-2 {
      opacity: 0;
      height: 0;
      overflow: hidden;
    }
  }
}
.password-title {
  color: #17b999;
}
@media only screen and (max-width: 450px) {
  .logo-wrap {
    margin-left: 30px;
  }
}
</style>
